.navbar-custom {
  background-color: #6c757d !important; /* Adjust this hex value to your preferred shade of gray */
}

.date-input {
  width: 250px;
  height: 50px;
  border-radius: 12px;
  font-size: 25px;
  text-align: center;
  border: 1px solid black;
  margin: 20px 20px;
  cursor: pointer;
}

.date-level {
  font-size: 25px;
  margin: 20px 20px;
}

.date-input input[type="date"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.Heads {
  text-transform: uppercase;
  color: #56a06c;
  text-align: center;
}

.image {
  inline-size: 80%;
  block-size: 80vh;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  margin-left: 500px;
  margin-top: 20px;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Printing Page */

#report {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

@media print {
  @page {
    size: 8.5in 11in;
    margin: 0.25in;
  }
  body {
    margin: 0;
  }
}
